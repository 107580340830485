import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './Home.module.css';
import { UserContext } from '../../context/UserContext'; // Import UserContext


export default function Home() {

  const { user } = useContext(UserContext); // Use UserContext
  const navigate = useNavigate();

  const handleTaxDeclarationClick = () => {
    if (user) {
      navigate('/tax-main');
    }  else {
      navigate('/tax-home');
    };
  };

  const handleCleaningServicesClick = () => {
    navigate('/cleaning');
    // window.location.href = process.env.REACT_APP_CONNECT_CLEAN_API_URL;
  };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>ConnectClean</h1>
                <h2>Manage your Short Term Rental</h2>
            </header>

            <div className={styles.flexContainer}>
                <div className={styles.section}>
                    <h3>Airbnb Tax Declaration</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique orci orci, at luctus velit dignissim in. Maecenas elit arcu, vestibulum quis rutrum in, cursus pharetra odio. Aenean sit amet porta orci. Pellentesque aliquet felis a fermentum scelerisque.</p>
                    <button className={styles.button} onClick={handleTaxDeclarationClick}>Prepare my Tax Declaration</button>
                </div>

                <div className={styles.section}>
                    <h3>Cleaning Services</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique orci orci, at luctus velit dignissim in. Maecenas elit arcu, vestibulum quis rutrum in, cursus pharetra odio. Aenean sit amet porta orci. Pellentesque aliquet felis a fermentum scelerisque.</p>
                    <button className={styles.button} onClick={handleCleaningServicesClick}>Go to Cleaning Services</button>
                </div>
            </div>
        </div>
    );
}
