import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { UserContext } from '../../../context/UserContext';
import { createProperty } from '../../../api/propertiesApi';
import { fetchUsers } from '../../../api/usersApi';

export default function PropertyForm() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    address: '',
    square_meters: '',
    number_rooms: '',
    number_bathrooms: '',
    has_cleaning_supplies: false,
    description: '',
    default_job_price: '',
    default_job_price_currency: 'EUR',
    default_cleaning_from: '12:00',
    default_cleaning_until: '16:00',
    photo: null,
    managers: [],
    cleaners: [],
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    loadUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      photo: e.target.files[0],
    }));
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedOptions.map(option => option.value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const propertyData = {
        property: {
          title: formData.title,
          address: formData.address,
          square_meters: formData.square_meters,
          number_rooms: formData.number_rooms,
          number_bathrooms: formData.number_bathrooms,
          has_cleaning_supplies: formData.has_cleaning_supplies,
          description: formData.description,
          default_job_price: formData.default_job_price,
          default_job_price_currency: formData.default_job_price_currency,
          default_cleaning_from: formData.default_cleaning_from,
          default_cleaning_until: formData.default_cleaning_until,
          photo: formData.photo,
        },
        managers: formData.managers,
        cleaners: formData.cleaners,
      };

      await createProperty(propertyData);
      navigate('/cleaning/properties'); // Redirect to the properties index after successful creation
    } catch (error) {
      console.error('Failed to create property:', error);
    }
  };

  const userOptions = users.map(user => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const sizeOptions = [
    5, 10, 15, 20, 25, 30, 40, 50, 65, 80, 100, 120, 140, 160, 180, 200, 230, 260, 300, 350, 400, 450, 500, 600, 700, 800, 1000, 1200, 1400, 1700, 2000, 2500, 3000
  ];

  const roomOptions = Array.from({ length: 21 }, (_, i) => i);

  const currencyOptions = [
    { label: '€', value: 'EUR' },
    { label: 'R$', value: 'BRL' },
    { label: '$', value: 'USD' }
  ];

  return (
    <div className="container mt-3 mb-3">
      <h1 className="mb-4 text-center mt-4">Add Property</h1>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 mb-4">
          <label>Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="mt-4 mb-3">
          <label>Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="mt-4 mb-3">
          <label>Square Meters</label>
          <select
            name="square_meters"
            value={formData.square_meters}
            onChange={handleChange}
            className="form-control"
          >
            {sizeOptions.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 mb-3">
          <label>Number of Rooms</label>
          <select
            name="number_rooms"
            value={formData.number_rooms}
            onChange={handleChange}
            className="form-control"
          >
            {roomOptions.map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 mb-3">
          <label>Number of Bathrooms</label>
          <select
            name="number_bathrooms"
            value={formData.number_bathrooms}
            onChange={handleChange}
            className="form-control"
          >
            {roomOptions.map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 mb-3">
          <label>
            <input
              type="checkbox"
              name="has_cleaning_supplies"
              checked={formData.has_cleaning_supplies}
              onChange={handleChange}
              className="form-check-input"
            />
            Has Cleaning Supplies
          </label>
        </div>
        <div className="form-group mb-3">
          <label>Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="form-control"
            rows={5}
          />
        </div>
        <div className="row">
          <div className="col-md-4">
            <label>Specify Budget</label>
          </div>
          <div className="col-md-4">
            <input
              type="number"
              name="default_job_price"
              value={formData.default_job_price}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-md-4">
            <select
              name="default_job_price_currency"
              value={formData.default_job_price_currency}
              onChange={handleChange}
              className="form-control"
            >
              {currencyOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <label>Cleaning From</label>
          <input
            type="time"
            name="default_cleaning_from"
            value={formData.default_cleaning_from}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="mt-4 mb-3">
          <label>Cleaning Until</label>
          <input
            type="time"
            name="default_cleaning_until"
            value={formData.default_cleaning_until}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="mt-4 mb-3">
          <label>Add Picture</label>
          <input
            type="file"
            name="photo"
            onChange={handleFileChange}
            className="form-control"
          />
        </div>

        {/* Select Managers */}
        <div className="mt-4 mb-3">
          <label>Assign Managers</label>
          <Select
            name="managers"
            options={userOptions}
            isMulti
            value={userOptions.filter(option => formData.managers.includes(option.value))}
            onChange={handleSelectChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        {/* Select Cleaners */}
        <div className="mt-4 mb-3">
          <label>Assign Cleaners</label>
          <Select
            name="cleaners"
            options={userOptions}
            isMulti
            value={userOptions.filter(option => formData.cleaners.includes(option.value))}
            onChange={handleSelectChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <div className="button mt-3 ms-2 mb-3 justify-content-center d-flex">
          <button type="submit" className="btn btn-primary rounded">
            Add Property
          </button>
        </div>
        <div className="button mt-3 ms-2 mb-3 justify-content-center d-flex">
          <button
            type="button"
            className="btn btn-danger rounded"
            onClick={() => navigate('/cleaning/properties')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
