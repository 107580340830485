import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../api/authUtils';
import { UserContext } from '../../context/UserContext';
import styles from './Login.module.css'; // Import the CSS module

function Login({ navigatePath}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/sign_in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: { email, password } }),
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        setToken(data.token);
        setUser(data.user);
        navigate(navigatePath);
      } else {
        setError(data.error || 'Unexpected error occurred');
      }
    } catch (error) {
      setError('Network error');
    }
  };

  const handleRegisterRedirect = () => {
    if (navigatePath == "/tax-main") {
      navigate('/register', { state: { navigatePath } });
    } else {
      navigate('/cleaning/register', { state: { navigatePath } });
    };
  };

  return (
    <div className={styles.loginContainer}>
      <h1 className={styles.loginTitle}>Login</h1>
      <form onSubmit={handleLogin} className={styles.loginForm}>
        <label className={styles.loginLabel}>
          Email:
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} className={styles.loginInput} />
        </label>
        <label className={styles.loginLabel}>
          Password:
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className={styles.loginInput} />
        </label>
        <button type="submit" className={styles.loginButton}>Login</button>
      </form>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <p className={styles.registerPrompt}>Don't have an account? <button onClick={handleRegisterRedirect} className={styles.registerButton}>Register here</button></p>
    </div>
  );
}

export default Login;
