import React from "react"
import { getStartOfMonth, getEndOfMonth } from "../../utils/dateUtils";

export default function DateNavigation(props) {

  function handlePrevious(){
    const newFrom = getStartOfMonth(new Date(props.from.getUTCFullYear(), props.from.getUTCMonth() - 1));
    const newUntil = getEndOfMonth(new Date(props.until.getUTCFullYear(), props.until.getUTCMonth() - 1));
    props.setFrom(newFrom);
    props.setUntil(newUntil);
  };

  function handleNext(){
    const newFrom = getStartOfMonth(new Date(props.from.getUTCFullYear(), props.from.getUTCMonth() + 1));
    const newUntil = getEndOfMonth(new Date(props.until.getUTCFullYear(), props.until.getUTCMonth() + 1));
    props.setFrom(newFrom);
    props.setUntil(newUntil);
  }

  // Function to format the month range
  const formatMonthRange = () => {
    const fromMonth = props.from.toLocaleDateString("default", { month: "long", timeZone: "UTC" });
    const fromYear = props.from.getUTCFullYear();
    const untilMonth = props.until.toLocaleDateString("default", { month: "long", timeZone: "UTC" });
    const untilYear = props.until.getUTCFullYear();

    if (fromYear < untilYear) {
      return `${fromMonth} ${fromYear} - ${untilMonth} ${untilYear}`;
    } else if (fromYear === untilYear) {
      if (props.from.getUTCMonth() === props.until.getUTCMonth()) {
        return fromMonth;
      } else if (props.from.getUTCMonth() < props.until.getUTCMonth()) {
        return `${fromMonth} - ${untilMonth}`;
      }
    }
    return "Date range is not valid";
  }

  return (
    <div className="date-navigation d-flex justify-content-around mb-4">
      <button className="btn btn-primary" onClick={handlePrevious}>Previous</button>
      <h2>{formatMonthRange()}</h2>
      <button className="btn btn-primary" onClick={handleNext}>Next</button>
    </div>
  )
}
