import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select'; // Import the Select component
import styles from './ManagerPropertyCard.module.css'; // Importing the CSS module
import { updateProperty } from '../../../api/propertiesApi'; // Import the API function to update a property
import { fetchUsers } from '../../../api/usersApi'; // Import the API function to fetch users
import MapComponent from './MapComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Set the root element for accessibility
Modal.setAppElement('#root');

export default function ManagerPropertyCard({ property }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editData, setEditData] = useState({ ...property });
  const [activeSection, setActiveSection] = useState('');
  const [weeksToImport, setWeeksToImport] = useState(4); // Default to 4 weeks
  const [users, setUsers] = useState([]);
  const[assignedManagers, setAssignedManagers] = useState(
    property.teams.filter((t) => t.profession === 'manager')
    .map((m) => ({
      id: m.user.id,
      first_name: m.user.first_name,
      last_name: m.user.last_name
    }))
  );
  const [assignedCleaners, setAssignedCleaners] = useState(
    property.teams.filter((t) => t.profession === 'cleaner')
    .map((c) => ({
      id: c.user.id,
      first_name: c.user.first_name,
      last_name: c.user.last_name
    }))
  );

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedCleaner, setSelectedCleaner] = useState(null);

  const [defaultJobPriceInput, setDefaultJobPriceInput] = useState(
    property.default_job_price_cents ? (property.default_job_price_cents / 100).toFixed(2) : ''
  );

  const [lastValidPriceInput, setLastValidPriceInput] = useState(defaultJobPriceInput);

  // Fetch users when component mounts
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    loadUsers();
  }, []);

  const availableManagers = users.filter(
    (user) => !assignedManagers.some((m) => m.id === user.id)
  )

  const availableCleaners = users.filter(
    (user) => !assignedCleaners.some((cleaner) => cleaner.id === user.id)
  );

  console.log('Assigned Managers:', assignedManagers);
  console.log('Assigned Cleaners:', assignedCleaners);
  console.log('Users:', users);
  console.log('Property:', property);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: name === 'has_cleaning_supplies' ? value === 'true' : value,
    }));
  };

  const handleWeeksChange = (e) => {
    setWeeksToImport(e.target.value);
  };

  const saveChanges = async () => {
    console.log('Saving changes', editData);

    try {
      // Call the API to update the property
      const updatedProperty = await updateProperty(editData.id, {
        ...editData,
        managers: assignedManagers.map((m) => m.id), // Send manager IDs to the backend
        cleaners: assignedCleaners.map((c) => c.id), // Send cleaner IDs to the backend
      });

      console.log('Property updated successfully:', updatedProperty);
      // Close the modal after saving changes
      closeSection();
    } catch (error) {
      console.error('Failed to save property changes:', error);
      alert('An error occurred while saving changes. Please try again.');
    }
  };

  const importListing = () => {
    console.log('Importing Airbnb Listing:', editData.airbnb_link);
    // Add your logic here to import the Airbnb listing details
    closeSection();
  };

  const importCalendar = async () => {
    console.log('Importing Calendar:', editData.ical_link, 'for', weeksToImport, 'weeks');

    try {
      // Call saveChanges to save the latest state to the backend
      await saveChanges();
      console.log('Changes saved after importing calendar.');
    } catch (error) {
      console.error('Error saving changes after calendar import:', error);
      alert('Failed to save changes after importing calendar. Please try again.');
    }
    closeSection();
  };

  const openSection = (section) => {
    setActiveSection(section);
    setModalIsOpen(true);
  };

  const closeSection = () => {
    setModalIsOpen(false);
    setActiveSection('');
  };

  return (
    <div className={styles.propertyCardsContainer}>
      {/* Update from Airbnb */}
      <div className={styles.propertyCard} onClick={() => openSection('Update from Airbnb')}>
        <h4 className={styles.cardTitle}>Update from Airbnb</h4>
        <p className={styles.cardContent}>
          Import your listing details automatically from Airbnb.
        </p>
      </div>

      {/* Title */}
      <div className={styles.propertyCard} onClick={() => openSection('Title')}>
        <h4 className={styles.cardTitle}>Title</h4>
        <p className={styles.cardContent}>{editData.title}</p>
      </div>

      {/* Pictures */}
      <div className={styles.propertyCard} onClick={() => openSection('Pictures')}>
        <h4 className={styles.cardTitle}>Pictures</h4>
        <p className={styles.cardContent}>Image preview here...</p>
      </div>

      {/* Property Description */}
      <div className={styles.propertyCard} onClick={() => openSection('Property Description')}>
        <h4 className={styles.cardTitle}>Property Description</h4>
        <p className={styles.cardContent}>{editData.description}</p>
        <p className={styles.cardContent}>Rooms: {editData.number_rooms}</p>
        <p className={styles.cardContent}>Bathrooms: {editData.number_bathrooms}</p>
      </div>

      {/* Location */}
      <div className={styles.propertyCard} onClick={() => openSection('Location')}>
        <h4 className={styles.cardTitle}>Location</h4>
        <p className={styles.cardContent}>{editData.address}</p>
      </div>

      {/* Cleaning Instructions */}
      <div className={styles.propertyCard} onClick={() => openSection('Cleaning Instructions')}>
        <h4 className={styles.cardTitle}>Cleaning Instructions</h4>
        <p className={styles.cardContent}>
          Available: {editData.default_cleaning_from} - {editData.default_cleaning_until}
        </p>
      </div>

      {/* My Team */}
      <div className={styles.propertyCard} onClick={() => openSection('My Team')}>
        <h4 className={styles.cardTitle}>My Team</h4>
        <p className={styles.cardContent}>
          Managers: {assignedManagers.map((manager) => manager.first_name).join(', ')}
        </p>
        <p className={styles.cardContent}>
          Cleaners: {assignedCleaners.map((cleaner) => cleaner.first_name).join(', ')}
        </p>
      </div>

      {/* Automate */}
      <div className={styles.propertyCard} onClick={() => openSection('Automate')}>
        <h4 className={styles.cardTitle}>Automate</h4>
        <p className={styles.cardContent}>Automatically create cleaning jobs when guests check out.</p>
      </div>

      {/* Full-Screen Modal for editing sections */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeSection}
        className={styles.popupModal}
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.modalHeader}>
          <button className={styles.closeButton} onClick={closeSection}>
            ✕
          </button>
          <h2 className={styles.modalTitle}>{activeSection}</h2>
        </div>
        <div className={styles.modalBody}>
          {/* Update from Airbnb Section */}
          {activeSection === 'Update from Airbnb' && (
            <>
              {/* Airbnb Listing URL */}
              <div className="mb-1">
                <label className="form-label">Airbnb Listing URL</label>
                <input
                  type="text"
                  name="airbnb_link"
                  value={editData.airbnb_link || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your Airbnb listing URL"
                />
                <small className="text-muted">
                  Enter your Airbnb listing URL to update your property details automatically.
                  This will import information like your title, description, and room details directly from Airbnb, saving you time on manual updates.
                </small>
              </div>
              <div className="d-flex justify-content-start mt-3">
                <button className="btn btn-primary" onClick={importListing}>
                  Import Listing
                </button>
              </div>

              {/* Centered Close Button at the Bottom */}
              <div className="d-flex justify-content-center mt-auto">
                <button className="btn btn-secondary" onClick={closeSection}>
                  Close
                </button>
              </div>
            </>
          )}

          {/* Title Section */}
          {activeSection === 'Title' && (
            <>
              <input
                type="text"
                name="title"
                value={editData.title}
                onChange={handleInputChange}
                className="form-control"
              />
            </>
          )}

          {/* Pictures Section */}
          {activeSection === 'Pictures' && (
            <>
              <div style={{ background: '#f5f5f5', height: '150px' }}>Image placeholder</div>
            </>
          )}

          {/* Property Description Section */}
          {activeSection === 'Property Description' && (
            <div className={styles.modalBody}>
              {/* Description Section */}
              <div className="mb-3" style={{ flexGrow: 1 }}>
                <label htmlFor="description" className="form-label">Description of the property</label>
                <textarea
                  id="description"
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                  className={`${styles['description-textarea']} form-control`}
                  style={{ height: '100%', minHeight: '100px' }} /* Ensure it occupies the available height */
                />
              </div>

              {/* Number of Rooms and Bathrooms Section */}
              <div className={`${styles.inputSection} mt-2`}>
                {/* Number of Rooms */}
                <div className={styles.inputRow}>
                  <label htmlFor="number_rooms" className={`${styles.labelField} form-label`}>
                    Number of Rooms:
                  </label>
                  <input
                    id="number_rooms"
                    type="number"
                    name="number_rooms"
                    value={editData.number_rooms}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>

                {/* Number of Bathrooms */}
                <div className={styles.inputRow}>
                  <label htmlFor="number_bathrooms" className={`${styles.labelField} form-label`}>
                    Number of Bathrooms:
                  </label>
                  <input
                    id="number_bathrooms"
                    type="number"
                    name="number_bathrooms"
                    value={editData.number_bathrooms}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>

                {/* Square Meters */}
                <div className={styles.inputRow}>
                  <label htmlFor="square_meters" className={`${styles.labelField} form-label`}>
                    Square meters of the property:
                  </label>
                  <input
                    id="square_meters"
                    type="number"
                    name="square_meters"
                    value={editData.square_meters}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>

              </div>
            </div>
          )}

          {/* Location Section */}
          {activeSection === 'Location' && (
            <>
              <input
                type="text"
                name="address"
                value={editData.address}
                onChange={handleInputChange}
                className="form-control"
              />
              <MapComponent markers={"markers"} />
            </>
          )}

          {/* Cleaning Instructions Section */}
          {activeSection === 'Cleaning Instructions' && (
            <>
              <small className="text-muted">
                  Write a brief description of the cleaning instructions for your property.
                  This will help your team understand the cleaning requirements and expectations.
                </small>
              <textarea
                name="cleaning_description"
                value={editData.cleaning_description || ''}
                onChange={handleInputChange}
                className="form-control"
                rows="7"
                placeholder="Enter cleaning instructions here"
              />
              <small className="text-muted mt-3">
                Specify the cleaning hours between which the property can be cleaned.
              </small>
              <div className="d-flex mt-1">
                <label htmlFor="default_cleaning_from" className="me-3">Between:</label>
                <input
                  type="time"
                  name="default_cleaning_from"
                  value={editData.default_cleaning_from}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control me-4`}
                />
                <label htmlFor="default_cleaning_until" className="me-3">and:</label>
                <input
                  type="time"
                  name="default_cleaning_until"
                  value={editData.default_cleaning_until}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control`}
                />
              </div>
              <div className="d-flex">
                <label htmlFor="has_cleaning_supplies" className="me-3">Are there cleaning supplies present at the property?</label>
                <select
                  name="has_cleaning_supplies"
                  value={editData.has_cleaning_supplies ? 'true' : 'false'}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control`}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </>
          )}

          {/* My Team Section */}
          {activeSection === 'My Team' && (
            <>
              <div className="mb-3">
                <p className={styles.cardContent}>Managers:</p>
                {assignedManagers.map((manager) => (
                  <div key={manager.id} className="d-flex align-items-center mb-2">
                    <span className="me-2">{`${manager.first_name} ${manager.last_name}`}</span>
                    <button
                      className={styles.trashIconButton}
                      onClick={() =>
                        setAssignedManagers((prev) =>
                          prev.filter((m) => m.id !== manager.id)
                        )
                      }
                    >
                      <FontAwesomeIcon icon="trash" className={styles.trashIcon} />
                    </button>
                  </div>
                ))}
                <Select
                  options={availableManagers.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                    user: user,
                  }))}
                  onChange={(selectedOption) => {
                    setAssignedManagers((prev) => [
                      ...prev,
                      { id: selectedOption.user.id,
                        first_name: selectedOption.user.first_name,
                        last_name: selectedOption.user.last_name
                      },
                    ])
                    setSelectedManager(null)
                  }}
                  value={selectedManager}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Add Manager"
                />
              </div>

              <div>
                <p className={styles.cardContent}>Cleaners:</p>
                {assignedCleaners.map((cleaner) => (
                  <div key={cleaner.id} className="d-flex align-items-center mb-2">
                    <span className="me-2">{`${cleaner.first_name} ${cleaner.last_name}`}</span>
                    <button
                      className={styles.trashIconButton}
                      onClick={() =>
                        setAssignedCleaners((prev) =>
                          prev.filter((c) => c.id !== cleaner.id)
                        )
                      }
                    >
                      <FontAwesomeIcon icon="trash" className={styles.trashIcon} />
                    </button>
                  </div>
                ))}
                <Select
                  options={availableCleaners.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                    user: user,
                  }))}
                  onChange={(selectedOption) => {
                    setAssignedCleaners((prev) => [
                      ...prev,
                      { id: selectedOption.user.id,
                        first_name: selectedOption.user.first_name,
                        last_name: selectedOption.user.last_name },
                    ]);
                    setSelectedCleaner(null);
                  }}
                  value={selectedCleaner}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Add Cleaner"
                />
              </div>
            </>
          )}

          {/* Automate Section */}
          {activeSection === 'Automate' && (
            <>
              <label className="form-label">Airbnb iCal Link</label>
              <input
                type="text"
                name="ical_link"
                value={editData.ical_link || ''}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Enter your Airbnb iCal link"
              />
              <small className="text-muted">
                Enter your Airbnb iCal link to automatically create cleaning jobs when guests check out.
                This will ensure your property is always ready for the next guest without needing to schedule cleaning tasks manually.
              </small>

              {/* Default Price Setting */}
              <div className="mt-4">
                <h5 className="form-label">Default Cleaning Price</h5>
                <div className={`input-group ${styles.inputGroupLimited}`}>
                  <input
                    type="text"
                    name="default_job_price"
                    inputMode="decimal" // Opens numeric keypad on mobile
                    value={defaultJobPriceInput}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDefaultJobPriceInput(value);
                    }}
                    onBlur={(e) => {
                      let value = e.target.value.replace(',', '.'); // Standardize to dot for decimal

                      // Check if input has more than one decimal separator or is not a valid number
                      if (!/^[0-9]*\.?[0-9]*$/.test(value) || isNaN(parseFloat(value))) {
                        alert('Please enter a valid number (e.g., 10.50 or 10,50). Only digits and a single decimal separator are allowed.');

                        // Revert to the previous valid value
                        setDefaultJobPriceInput(lastValidPriceInput); // Revert to the last valid input
                        return;
                      }

                      // Convert to cents if valid
                      const convertedCents = Math.round(parseFloat(value) * 100);

                      setEditData((prevData) => ({
                        ...prevData,
                        default_job_price_cents: convertedCents, // Update the state with the correct cents value
                      }));

                      // Update the input with formatted value and set the last valid input
                      setDefaultJobPriceInput((convertedCents / 100).toFixed(2));
                      setLastValidPriceInput((convertedCents / 100).toFixed(2)); // Store the last valid input
                    }}
                    className="form-control"
                    placeholder="Set default price for cleaning jobs"
                  />
                  <span className="input-group-text">EUR</span>
                </div>
                <small className="text-muted">
                  Enter the default price using a numeric value (e.g., 10.50). This is the base price for cleaning jobs at this property. It will apply to all cleaners unless overridden with a specific price.
                </small>
              </div>

              {/* Cleaner-Specific Pricing */}
              <div className="mt-4">
                <h5 className="form-label">Cleaner-Specific Pricing</h5>
                <Select
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                    cleaner,
                  }))}
                  onChange={(selectedOption) => {
                    setSelectedCleaner(selectedOption.cleaner);
                  }}
                  value={selectedCleaner}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Cleaner to Set Custom Price"
                />
                {selectedCleaner && (
                  <input
                    type="number"
                    name={`price_${selectedCleaner.id}`}
                    value={editData[`price_${selectedCleaner.id}`] || ''}
                    onChange={handleInputChange}
                    className="form-control mt-2"
                    placeholder={`Set custom price for ${selectedCleaner.first_name} ${selectedCleaner.last_name}`}
                  />
                )}
              </div>

              {/* Cleaner Assignment Rules */}
              <div className="mt-4">
                <h5 className="form-label">Cleaner Assignment Rules</h5>
                <label className="form-label">Primary Cleaner</label>
                <Select
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                  }))}
                  onChange={(selectedOption) => setEditData({ ...editData, primaryCleaner: selectedOption.value })}
                  value={assignedCleaners.find((c) => c.id === editData.primaryCleaner) || null}
                  className="basic-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Primary Cleaner"
                />

                <label className="form-label">Fallback Cleaners</label>
                <Select
                  isMulti
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                  }))}
                  onChange={(selectedOptions) => setEditData({ ...editData, fallbackCleaners: selectedOptions.map((opt) => opt.value) })}
                  value={assignedCleaners.filter((c) => editData.fallbackCleaners?.includes(c.id))}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Fallback Cleaners"
                />

                <label className="form-label">Response Timeframe (hours)</label>
                <input
                  type="number"
                  name="response_timeframe"
                  value={editData.response_timeframe || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Set response timeframe in hours"
                />
                <small className="text-muted">
                  Set the maximum time (in hours) for the primary cleaner to respond before the job is reassigned to the next cleaner.
                </small>
              </div>

              {/* Job Posting Automation */}
              <div className="mt-4">
                <h5 className="form-label">Job Posting Automation</h5>
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="auto_post_jobs"
                    checked={editData.auto_post_jobs || false}
                    onChange={(e) => setEditData({ ...editData, auto_post_jobs: e.target.checked })}
                    className="form-check-input"
                  />
                  <label className="form-check-label">Enable Automatic Job Posting</label>
                </div>
                <small className="text-muted">
                  Automatically post jobs to cleaners based on your rules and calendar events.
                </small>
              </div>

              {/* Calendar Import Settings */}
              <div className="mt-4">
                <h5 className="form-label">Import Calendar Settings</h5>
                <label className="form-label">Import Calendar for:</label>
                <select
                  name="weeksToImport"
                  value={weeksToImport}
                  onChange={handleWeeksChange}
                  className="form-control"
                >
                  <option value={1}>1 Week</option>
                  <option value={2}>2 Weeks</option>
                  <option value={4}>4 Weeks</option>
                  <option value={8}>8 Weeks</option>
                </select>
                <small className="text-muted">
                  Select how many weeks ahead you would like to import your calendar. This setting determines how far in advance cleaning jobs will be scheduled.
                </small>
              </div>

              <div className="d-flex justify-content-start">
                <button className="btn btn-primary mt-2" onClick={importCalendar}>
                  Import Calendar
                </button>
              </div>

              {/* Centered Close Button at the Bottom */}
              <div className="d-flex justify-content-center mt-auto">
                <button className="btn btn-secondary" onClick={closeSection}>
                  Close
                </button>
              </div>
            </>
          )}
        </div>

        {/* Save and Cancel (Close) Buttons for Other Sections */}
        {activeSection !== 'Update from Airbnb' && activeSection !== 'Automate' && (
          <div className={styles.modalFooter}>
            <button className="btn btn-secondary" onClick={closeSection}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={saveChanges}>
              Save
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}
