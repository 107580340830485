export const setToken = (token) => {
  const inOneDay = new Date(new Date().getTime() + 86400 * 1000);
  document.cookie = `jwt=${token}; expires=${inOneDay.toUTCString()}; path=/; SameSite=Lax`;
};


/**
 * Retrieve the JWT token from cookies.
 */
export const getToken = () => {
  const match = document.cookie.match(new RegExp('(^| )jwt=([^;]+)'));
  return match ? match[2] : null;
};

/**
 * Clear the JWT token from cookies.
 */
export const clearToken = () => {
  document.cookie = 'jwt=; Max-Age=-99999999; path=/; SameSite=Lax';
  document.cookie = 'jwt_token=; Max-Age=-99999999; path=/; SameSite=Lax';
};


export const getCurrentUserInfo = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/user`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
};

/* Log out the user by clearing the token and making a logout request to the backend.
 */

export const logoutUser = async () => {
  try {
    await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/auth/sign_out`, {
      method: 'DELETE',
      credentials: 'include',
    });
    clearToken();
  } catch (error) {
    console.error('Error logging out:', error);
  }
};
