import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import FilterDropdown from "../../../../../components/FilterDropdown/FilterDropdown";
import JobItem from '../JobItem/JobItem';
import SummaryStats from "../SummaryStats/SummaryStats";

export default function JobList(props) {

  const [sortField, setSortField] = React.useState("date_of_job");
  const [sortOrder, setSortOrder] = React.useState("asc");

  const columns = [
    { label: "Property Name", field: "property_title" },
    { label: props.userRole === "manager" ? "Cleaner" : "Manager", field: props.userRole === "manager" ? "cleaner_first_name" : "manager_first_name" },
    { label: "Date", field: "date_of_job" },
    { label: "Price", field: "price_cents" },
  ];

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
      if (valueA === valueB) return 0;
      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });
  };

  const sortedJobs = sortJobs(props.jobs);

  // State to control the visibility of the filter dropdown
  const [showFilters, setShowFilters] = React.useState({});

  // Function to toggle the visibility of the filter dropdown for a specific field
  const toggleFilter = (field) => {
    setShowFilters((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Function to extract unique values from a field in the jobs array
  const extractUniqueValues = (jobs, field) => {
    return [...new Set(jobs.map(job => {
      if (field === "price_cents") {
        return (job[field] / 100).toFixed(2);
      } else {
        return job[field];
      }
    }))];
  };

  // Generate options for the filter dropdown
  const options = columns.map(col => {
    return {
      value: col.field,
      label: col.label,
      options: extractUniqueValues(sortedJobs, col.field).map(value => ({
        value,
        label: value
      }))
    };
  });

  console.log("The options are: ", options);

  // State to store the selected filter options for each field
  const [filterOptions, setFilterOptions] = React.useState({});

  // Handle filter change
  const handleFilterChange = (selectedOption, field) => {
    if (selectedOption === null) {
      // Clear filter for the specified field
      setFilterOptions((prev) => {
        const updatedFilters = { ...prev };
        delete updatedFilters[field];
        return updatedFilters;
      });
    } else {
      setFilterOptions((prev) => ({
        ...prev,
        [field]: selectedOption,
      }));
    }
  };

  console.log("The selected filter options are: ", filterOptions)

  // Filter the jobs based on the selected filter options
  const filteredJobs = sortedJobs.filter(job => {
    return Object.keys(filterOptions).every(field => {
      if (field === "price_cents") {
        return (job[field] / 100).toFixed(2) === filterOptions[field].value;
      } else {
      return job[field] === filterOptions[field].value;
      }
    });
  });
  console.log("The filtered jobs are: ", filteredJobs);

  const renderedJobs = filteredJobs.map((job, index) => (
      <JobItem key={index} job={job} columns={columns} /> // Using JobItem component
  ));


  React.useEffect(() => {
    props.setFieldFilteredJobs(filteredJobs);
  }, [filterOptions, props.from, props.until]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SummaryStats jobs={filteredJobs}/>
      <div className="container mt-4 text-center">
        <div className="row mb-2 p-3 border rounded">
          <div className="row m2">
            {columns.map((col, colIndex) => (
              <div key={colIndex} className="col border-right">
                <button
                  type="button"
                  onClick={() => handleSortChange(col.field)}
                  className="text-primary btn btn-link p-0 border-0 bg-transparent"
                >
                  {col.label}
                  {sortField === col.field ? (sortOrder === "asc" ? ' 🔽' : ' 🔼') : ''}
                </button>
                <button
                  onClick={() => toggleFilter(col.field)}
                  style={{ background: 'none', border: 'none', marginLeft: "5px", opacity: showFilters[col.field] ? 1 : 0.5 }}
                >
                    <FontAwesomeIcon icon={faFilter} />
                </button>
                {showFilters[col.field] && (
                  <FilterDropdown
                    options={options.find(opt => opt.value === col.field).options}
                    placeholder="Select an option"
                    onChange={(selectedOption) => handleFilterChange(selectedOption, col.field)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        {renderedJobs}
      </div>
    </>
  );
}
