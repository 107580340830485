import React, { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

export default function CleanHome() {
  const { currentUser } = useContext(UserContext);

  return (
    <div data-controller="pages">
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: '20px', padding: '10px' }}>
        <h1><strong>Welcome To Connect & Clean</strong></h1>
        {/* Render buttons based on whether the user is logged in */}
        {!currentUser && (
          <div className="d-flex my-3">
            <a href="/jobs?role=visitor" className="btn btn-success me-2">Find a Job</a>
            <a href="/manager_page?role=visitor" className="btn btn-success ms-2">Find a Cleaner</a>
          </div>
        )}
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2>Where we Connect Cleaners</h2>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <h2>To your Properties</h2>
      </div>

      <header className="jumbotron text-center">
        <h1 className="display-4">Effortless Cleaning Management for Short-Term Rentals</h1>
        <p className="lead">Connecting Rental Owners with Top Cleaners & Simplifying Your Scheduling</p>
      </header>

      <div className="container">
        <section className="my-5">
          <h2>For Property Owners</h2>
          <p>Are you renting your property on Airbnb, Booking.com, or another platform? Connectclean streamlines your cleaning management, making your life easier. Whether you need occasional cleaning or regular upkeep, we've got you covered.</p>
        </section>

        <section className="my-5">
          <h2>For Cleaners</h2>
          <p>Looking for flexible cleaning work? With Connectclean, discover a range of jobs that fit your schedule. Whether it's a side gig or a steady income, opportunities abound.</p>
        </section>

        <section className="my-5">
          <h2>We Are a Marketplace</h2>
          <p>At Connectclean, we bridge the gap between short-term rental owners and professional cleaners. Owners: Post cleaning jobs easily, review applications, and select cleaners that meet your needs. Cleaners: Browse available jobs and apply with just a click. No long-term commitments, just opportunities.</p>
        </section>

        <section className="my-5">
          <h2>Simplifying Your Administration</h2>
          <p>Automated Scheduling: Sync your rental calendars (Airbnb, Booking.com, etc.) for automatic job postings. Build Your Team: Add your favorite cleaners to your team for priority job acceptance.</p>
        </section>

        <section className="my-5">
          <h2>Financial Transparency</h2>
          <p>Keep track of your earnings or cleaning expenses through our intuitive dashboard. Know where you stand financially at a glance.</p>
        </section>

        <section className="text-center my-5">
          <h2>Currently Free to Use!</h2>
          <p>We're focused on delivering the best user experience. Enjoy our comprehensive features at no cost.</p>
          <div className="d-flex my-3 justify-content-center">
            {!currentUser && (
              <>
                <a href="/jobs?role=visitor" className="btn btn-success me-2">Find a Job</a>
                <a href="/manager_page?role=visitor" className="btn btn-success ms-2">Find a Cleaner</a>
              </>
            )}
          </div>
        </section>

        <section className="my-5">
          <h2>About Us</h2>
          <p>Connectclean was born from a simple idea: to make property management easier. Our mission is to streamline the cleaning process, making it efficient and stress-free for both property owners and cleaners.</p>
        </section>

        <section className="my-5">
          <h2>Let's Build Together</h2>
          <p>Your feedback drives our innovation. Looking for specific features like automatic payments, handyman services, or specialized cleaning tasks? Reach out to us at <a href="mailto:ben@connectclean.net">ben@connectclean.net</a></p>
        </section>
      </div>
    </div>
  );
}
