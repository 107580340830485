import React from "react"
import DateNavigation from './components/DateNavigation/DateNavigation';
import DateFilter from './components/DateFilter/DateFilter';
import JobList from './components/JobList/JobList';
import JobItem from './components/JobItem/JobItem';
import { getStartOfMonth, getEndOfMonth } from './utils/dateUtils';
import { fetchDashboardData } from './utils/apiUtils';
import styles from "./Dashboard.module.css"

export default function Dashboard() {

  const [jobs, setJobs] = React.useState([]);
  const [userRole, setUserRole] = React.useState('');

  // + 'Z' is added to the end of the string to make sure it is UTC time
  const [from, setFrom] = React.useState(getStartOfMonth(new Date()));
  const [until, setUntil] = React.useState(getEndOfMonth(new Date()));

  // Function to filter jobs based on the date range
  const filterJobsByDate = (jobs, fromDate, untilDate) => {
    console.log(jobs);
    if (!jobs) return [];
    return jobs.filter(job => {
      const jobDate = new Date(job.date_of_job);
      return jobDate >= fromDate && jobDate <= untilDate;
    });
  };

  // Get the Data from the Ruby on Rails API
  React.useEffect(() => {
    fetchDashboardData()
      .then(data => {
        setJobs(data.jobs)
        setUserRole(data.user_role);
        console.log(data.user_role);
      });
  }, []);


  // Filtered jobs for the currently selected date range
  const dateFilteredJobs = filterJobsByDate(jobs, from, until);

  const [fieldFilteredJobs, setFieldFilteredJobs] = React.useState(dateFilteredJobs);

  React.useEffect(() => {
    console.log('fieldFilteredJobs updated', fieldFilteredJobs);
  }, [fieldFilteredJobs]);

  return (

    <div>
      <h1 className={styles.dashboardTitle}>Dashboard</h1>
      <DateNavigation from={from} setFrom={setFrom} until={until} setUntil={setUntil} />
      <DateFilter
        from={from}
        setFrom={setFrom}
        until={until}
        setUntil={setUntil}
      />
      <JobList jobs={dateFilteredJobs} userRole={userRole} fieldFilteredJobs={fieldFilteredJobs} setFieldFilteredJobs={setFieldFilteredJobs} from={from} until={until}/>
      <JobItem />
    </div>
  )
}
