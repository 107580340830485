import React from "react"

export default function SummaryStats(params) {

  function getNumberOfJobs(jobs){
    return jobs.length;
  }

  function getTotalPrice(jobs) {
    const currencyTotals = jobs.reduce((acc, job) => {
      const currency = job.price_currency;
      const price = job.price_cents / 100; // Convert cents to currency unit
      if (acc[currency]) {
        acc[currency] += price;
      } else {
        acc[currency] = price;
      }
      return acc;
    }, {});

    return currencyTotals;
  }

  function formatTotalPrices(jobs) {
    const totalPrice = getTotalPrice(jobs);
    return Object.entries(totalPrice).map(([currency, total], index) => (
      <p key={index}>
        {total.toFixed(2)} {currency}
      </p>
    ));
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6 text-center mb-4">
          <h2 className="text-primary font-weight-bold">Total Jobs</h2>
          <h2 className="font-weight-bold">{getNumberOfJobs(params.jobs)}</h2>
        </div>
        <div className="col-md-6 text-center mb-4">
          <h2 className="text-primary font-weight-bold">Total Price</h2>
          {formatTotalPrices(params.jobs)}
        </div>
      </div>
    </div>
  )
}
