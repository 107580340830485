import React from 'react';

export default function CleanerPropertyCard({ property }) {
  const photoStyle = {
    backgroundImage: property.photo
      ? `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), url(${property.photo})`
      : 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)), url(https://res.cloudinary.com/dgabkyhfa/image/upload/v1694529741/sexs5cxn4dj15cav0qs3.jpg)',
  };

  const owner = property.owner || { first_name: 'Unknown' };

  return (
    <div className="container text-bg-light rounded-4 p-3 mb-5">
      <h1 className="d-flex flex-column justify-content-center align-items-center mb-4 mt-4">
        {property.title}
      </h1>

      <div className="row justify-content-evenly">
        <div className="col-md-5 col-12 m-2 card-category" style={photoStyle}></div>
        <div id="name-address" className="col-md-5 col-12 m-2 d-flex flex-md-column">
          <div id="Name" className="mb-4 me-2 col-6">
            <p className="mb-2 text-secondary">Name</p>
            <p>{property.title}</p>
          </div>
          <div id="address" className="mt-4 col-6">
            <p className="mb-2 text-secondary">Address</p>
            <p>{property.address}</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6 col-md-3 mb-5 mt-3">
          <p className="mb-2 text-secondary">Square Meters</p>
          <p className="text-wrap text-break">{property.square_meters}</p>
        </div>
        <div className="col-6 col-md-3 mb-5 mt-3">
          <p className="mb-2 text-secondary">Bedrooms</p>
          <p className="text-wrap text-break">{property.number_rooms}</p>
        </div>
        <div className="col-6 col-md-3 mb-5 mt-3">
          <p className="mb-2 text-secondary">Bathrooms</p>
          <p className="text-wrap text-break">{property.number_bathrooms}</p>
        </div>
        <div className="col-6 col-md-3 mb-5 mt-3">
          <p className="mb-2 text-secondary">Cleaning Supplies</p>
          <p className="text-wrap text-break">
            {property.has_cleaning_supplies ? 'Yes' : 'No'}
          </p>
        </div>
      </div>

      <div className="row mt-4 mb-2">
        <div className="col-md-4 col-6">
          <div id="Available-between" className="mb-4">
            <p className="mb-2 text-secondary">Cleaning Between</p>
            <p>
              From: {property.default_cleaning_from} Until: {property.default_cleaning_until}
            </p>
          </div>
        </div>

        <div id="price" className="col-md-4 col-6">
          <p className="mb-2 text-secondary">Earnings Cleaning</p>
          <p>{property.default_job_price_cents / 100} {property.default_job_price_currency}</p>
        </div>

        <div className="col-md-4 col-12">
          <p className="mb-2 text-secondary">Owned by {owner.first_name}</p>
          <div className="btn btn-primary btn-rounded">
            <button>
              Chat with {owner.first_name}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12 mb-5 mt-3">
        <p className="mb-2 text-secondary">Cleaning Description</p>
        <p className="text-wrap text-break">{property.description}</p>
      </div>

      <div style={{ width: '100%', height: '300px' }}>
        {/* Map placeholder, to be implemented later */}
      </div>
    </div>
  );
}
