import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { UserContext } from '../../../context/UserContext';
import styles from './TaxDetail.module.css';
import TaxNavbar from '../../components/TaxNavbar/TaxNavbar';

Modal.setAppElement('#root'); // Bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function TaxDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { data } = location.state || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!data) {
    return <div className={styles.container}><div className={styles.content}>No data available</div></div>;
  }

  const taxDataPerProperty = data.tax_data_per_property || {};
  const taxDataTotal = data.tax_data_total || {};
  const earningsYear = data.earnings_year || "";

  console.log(`The data object is: ${JSON.stringify(data)}`);

  const handleGetCalculationExcel = async () => {
    if (!user) {
      setIsModalOpen(true);
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/get_calculation_excel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch calculation');
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error('Error fetching calculation:', error);
    }
  };

  return (
    <div>
      <TaxNavbar />
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>Tax Details for Fiscal Year {earningsYear}</h1>
          <p className={styles.description}>Here are the tax details for your properties:</p>
          {Object.keys(taxDataPerProperty).map((property, index) => (
            <div key={index} className={styles.propertyContainer}>
              <h2 className={styles.propertyTitle}>{property}</h2>
              <div className={styles.stats}>
                <p>Total Income: {taxDataPerProperty[property].income}</p>
                <p>Cleaning Fees: {taxDataPerProperty[property].cleaning_fees}</p>
                <p>Airbnb Service Fee: {taxDataPerProperty[property].airbnb_service_fee}</p>
                <p>Occupancy Rate: {taxDataPerProperty[property].occupancy_rate}</p>
                <p>Roerende Inkomsten: {taxDataPerProperty[property].roerende_inkomsten}</p>
                <p>Diverse Inkomsten: {taxDataPerProperty[property].diverse_inkomsten}</p>
                <p>Onroerende Inkomsten: {taxDataPerProperty[property].onroerende_inkomsten}</p>
              </div>
            </div>
          ))}
          {taxDataTotal && (
            <div className={styles.totalContainer}>
              <h2 className={styles.propertyTitle}>Total</h2>
              <div className={styles.stats}>
                <p>Total Income: {taxDataTotal.income}</p>
                <p>Cleaning Fees: {taxDataTotal.cleaning_fees}</p>
                <p>Airbnb Service Fee: {taxDataTotal.airbnb_service_fee}</p>
                <p>Roerende Inkomsten: {taxDataTotal.roerende_inkomsten}</p>
                <p>Diverse Inkomsten: {taxDataTotal.diverse_inkomsten}</p>
                <p>Onroerende Inkomsten: {taxDataTotal.onroerende_inkomsten}</p>
              </div>
            </div>
          )}
          <button className={styles.printBtn} onClick={handleGetCalculationExcel}>Get Calculation Excel</button>
          {user && (
            <button className={styles.backBtn} onClick={() => navigate('/tax-main')}>Back to Main</button>
          )}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Register Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>You need to be registered</h2>
        <p>Please register to get the calculation excel.</p>
        <button onClick={() => navigate('/register')} className={styles.registerBtn}>Register</button>
        <button onClick={() => setIsModalOpen(false)} className={styles.closeBtn}>Close</button>
      </Modal>
    </div>
  );
}

export default TaxDetail;
