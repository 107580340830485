import React from "react";

export default function JobItem({ job, columns }) {
  // Check if columns is defined
  if (!columns) {
    console.log("Columns not defined in JobItem");
    return null
  }

  return (
    <div className="row mb-2 p-3 border rounded">
      {columns.map((col, colIndex) => (
        <div key={colIndex} className="col">
          {col.field === "price_cents"
            ? `${(job[col.field] / 100).toFixed(2)} ${job.price_currency}`
            : job[col.field]}
        </div>
      ))}
    </div>
  );
}
