import React from "react"

export default function DateFilter(props) {

  function handleFromDateChange(event){
    props.setFrom(new Date(event.target.value));
  }

  function handleUntilDateChange(event){
    props.setUntil(new Date(event.target.value));
  }

  return (
    <>
      <p className="d-flex justify-content-center">Filter for more specific dates</p>
      <div className="d-flex justify-content-center align-items-center">
        <div className="me-3">
          <label htmlFor="from_date">From</label>
          <input
            type="date"
            id="from_date"
            name="from_date"
            value={props.from.toISOString().split('T')[0]}
            onChange={handleFromDateChange}
          />
        </div>
        <div className="me-4">
          <label htmlFor="until_date">Until</label>
          <input
            type="date"
            id="until_date"
            name="until_date"
            value={props.until.toISOString().split('T')[0]}
            onChange={handleUntilDateChange}
          />
        </div>
      </div>
    </>
  )
}
