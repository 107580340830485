import React from "react"
import { Outlet } from "react-router-dom"
import Header from "../Header/Header"
import styles from "./Layout.module.css"

export default function Layout() {
    return (
        <div className={styles.siteWrapper}>
            <Header />
            <main>
                <Outlet />
            </main>
        </div>
    )
}
