import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './UploadKI.module.css';
import TaxNavbar from '../../components/TaxNavbar/TaxNavbar'; // Import TaxNavBar

function UploadKi() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  console.log(data)

  const [kiValues, setKiValues] = useState(
    Object.keys(data.tax_data_per_property).reduce((acc, property) => {
      acc[property] = data.tax_data_per_property[property].ki || '';
      return acc;
    }, {})
  );

  console.log(kiValues);

  const handleKiChange = (event, property) => {
    setKiValues({
      ...kiValues,
      [property]: event.target.value
    });
  };

  const handleSubmit = async () => {
    const updatedTaxDataPerProperty = Object.keys(data.tax_data_per_property).reduce((acc, property) => {
      acc[property] = {
        ...data.tax_data_per_property[property],
        ki: kiValues[property]
      };
      return acc;
    }, {});

    const updatedData = {
      ...data,
      tax_data_per_property: updatedTaxDataPerProperty
    };

    console.log(updatedData);
    console.log(JSON.stringify({ data: updatedData }))

    const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/calculate_taxes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: updatedData }),
      credentials: 'include',
    });

    const result = await response.json();
    navigate('/tax-detail', { state: { data: result.data } });
  };

  return (
    <div>
      <TaxNavbar />
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>Upload KI Per Property</h1>
          <p className={styles.description}>
            Please input the KI values for each property to calculate the full tax details.
          </p>
          {data && Object.keys(data.tax_data_per_property).map((property, index) => (
            <div key={index} className={styles.propertyContainer}>
              <h2 className={styles.propertyTitle}>{property}</h2>
              <div className={styles.stats}>
                <p>Total Income: {data.tax_data_per_property[property].income}</p>
                <p>Cleaning Fees: {data.tax_data_per_property[property].cleaning_fees}</p>
                <p>Airbnb Service Fee: {data.tax_data_per_property[property].airbnb_service_fee}</p>
                <p>Occupancy Rate: {data.tax_data_per_property[property].occupancy_rate}</p>
                <p>Roerende Inkomsten: {data.tax_data_per_property[property].roerende_inkomsten}</p>
                <p>Diverse Inkomsten: {data.tax_data_per_property[property].diverse_inkomsten}</p>
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.label}>KI Value:</label>
                <input
                  type="number"
                  value={kiValues[property]}
                  onChange={(e) => handleKiChange(e, property)}
                  className={styles.input}
                />
              </div>
            </div>
          ))}
          <button onClick={handleSubmit} className={styles.submitButton}>Submit KI Values</button>
        </div>
      </div>
    </div>
  );
}

export default UploadKi;
