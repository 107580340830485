import React, { useEffect, useState } from 'react';
import { getToken } from '../../../api/authUtils';
import styles from './Test.module.css';
import psychedelicStyles from './Psychedelic.module.css';

function Test() {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState('');
  const [isPsychedelic, setIsPsychedelic] = useState(false);

  useEffect(() => {
    const token = getToken();
    const fetchJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/en/dashboard_data`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.jobs) {
          setJobs(data.jobs);
        } else {
          setError('No jobs data received');
        }
      } catch (err) {
        console.error('Failed to fetch jobs:', err);
        setError('Failed to fetch jobs');
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const toggleStyle = () => {
      setIsPsychedelic((prev) => !prev);
      const randomInterval = Math.random() * 5000 + 500;
      setTimeout(toggleStyle, randomInterval);
    };

    const initialToggleInterval = Math.random() * 5000 + 5000;
    const initialTimeout = setTimeout(toggleStyle, initialToggleInterval);

    return () => clearTimeout(initialTimeout);
  }, []);

  return (
    <div className={isPsychedelic ? psychedelicStyles.psychedelicContainer : styles.testContainer}>
      <h1 className={isPsychedelic ? psychedelicStyles.psychedelicTitle : styles.testTitle}>Test Jobs Page</h1>
      {error && <p className={isPsychedelic ? psychedelicStyles.psychedelicErrorMessage : styles.errorMessage}>{error}</p>}
      <div className={isPsychedelic ? psychedelicStyles.psychedelicJobContainer : styles.jobContainer}>
        {jobs.map((job, index) => (
          <div key={index} className={isPsychedelic ? psychedelicStyles.psychedelicJobItem : styles.jobItem}>
            {job.property_title} - {new Date(job.date_of_job).toLocaleDateString()}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Test;
