import React, { useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logonavbar.png';
import { UserContext } from '../../../context/UserContext';

export default function Header() {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout(); // Use the logout function from UserContext
    navigate('/cleaning'); // Navigate to /cleaning after logging out
};

  const activeStyles = {
      fontWeight: "bold",
      textDecoration: "underline",
      color: "#161616"
  };

  const backend = process.env.REACT_APP_CONNECT_CLEAN_API_URL;

  return (
      <header>
          <nav className="navbar navbar-expand-sm navbar-light navbar-lewagon">
              <div className="container-fluid">
                  <a href="/cleaning" className="navbar-brand">
                      <img src={logo} alt="Le Wagon" width="40" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav me-auto menu__list r-list">
                          {user ? (
                              <>
                                  <li className="nav-item">
                                      <a href={"/cleaning/find-user"} className="nav-link menu__link r-link text-underlined">
                                          Find Cleaner
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a href={"/cleaning/calendar"} className="nav-link menu__link r-link text-underlined">
                                          Calendar
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a href={"/cleaning/properties"} className="nav-link menu__link r-link text-underlined">
                                          My Properties
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a href={"/cleaning/messages"} className="nav-link menu__link r-link text-underlined">
                                          Messages
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <NavLink
                                          to="cleaning/dashboard"
                                          className="nav-link menu__link r-link text-underlined"
                                          style={({ isActive }) => isActive ? activeStyles : null}
                                      >
                                          Dashboard
                                      </NavLink>
                                  </li>
                                  <li className="nav-item">
                                      <button className="btn btn-secondary" onClick={handleLogout}>
                                          Logout
                                      </button>
                                  </li>
                              </>
                          ) : (
                              <li className="nav-item">
                                  <NavLink to="/cleaning/login" className="nav-link menu__link r-link text-underlined">
                                      Login
                                  </NavLink>
                              </li>
                          )}
                      </ul>
                  </div>
              </div>
          </nav>
      </header>
  );
}
