import React, { useState } from "react";
import Select from "react-select";

const FilterDropdown = ({ options, placeholder, onChange, isMulti = false }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClearFilters = () => {
    setSelectedOption(null);
    onChange(null);
  };

  const handleOnChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <div>
      <Select
        options={options}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={selectedOption}
        isMulti={isMulti}
      />
      <button
        onClick={handleClearFilters}
        style={{ background: 'none', marginLeft: "5px", opacity: options ? 1 : 0.5, marginTop: "5px", backgroundColor: "white", border: "1px solid black"}}
      >
        Clear Filter
      </button>
    </div>
  );
};

export default FilterDropdown;
